import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { FaArrowRight } from 'react-icons/fa';
import styles from './PostList.module.scss';

const cx = classNames.bind(styles);

const PostItem = ({ title, date, tags, excerpt, image, slug }) => {
  const tagList = tags.map(tag => (
    <Link key={kebabCase(tag)} to={`/tags/${kebabCase(tag)}/`}>
      #{tag}
    </Link>
  ));
  const isExistImage = !!image;

  return (
    <div className={cx('post-item')}>
      {isExistImage ? (
        <div className={cx('thumbnail')}>
          <Link to={slug}>
            <img src={image.childImageSharp.fluid.src} alt="" />
          </Link>
        </div>
      ) : null}
      <div className={cx('content', isExistImage ? 'content-reduce' : null)}>
        <div className={cx('date')}>{date}</div>
        <h2>
          <Link to={slug}>{title}</Link>
        </h2>
        <div className={cx('tags')}>{tagList}</div>
        <p>{excerpt}</p>
        <Link to={slug} className={cx('more-link')}>
          Keep Reading <FaArrowRight />
        </Link>
      </div>
    </div>
  );
};

const PostList = ({ posts }) => {
  const postList = posts.map(post => {
    const { title, date, tags, excerpt, image, slug, id } = post;
    return (
      <PostItem
        title={title}
        date={date}
        tags={tags}
        excerpt={excerpt}
        image={image}
        key={id}
        slug={slug}
      />
    );
  });

  return <div className={cx('post-list')}>{postList}</div>;
};

export default PostList;
