import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import { FaBars, FaSearch } from 'react-icons/fa';
import Navbar from 'src/components/common/Navbar';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

class Header extends Component {
  // handleClickSearchBtn = () => {
  //   const { onHideMobileNav, onShowSearchModal } = this.props;
  //   onHideMobileNav();
  //   onShowSearchModal();
  // };

  render() {
    const {
      onToggleMobileNav,
      onHideMobileNav,
      isMobileMode,
      visibleMobileNav,
    } = this.props;
    // const { handleClickSearchBtn } = this;
    return (
      <header className={cx('header')}>
        <div className={cx('header-content')}>
          <div className={cx('left')}>
            <div className={cx('bars')}>
              <FaBars onClick={onToggleMobileNav} />
            </div>
          </div>
          <div className={cx('logo')}>
            <Link to="/" title="Logo" onClick={onHideMobileNav}>
              YUNHEUR
            </Link>
            <h1 className={cx('blind')}>YUNHEUR</h1>
          </div>
          <div className={cx('nav')} onClick={onHideMobileNav}>
            {isMobileMode ? visibleMobileNav && <Navbar /> : <Navbar />}
          </div>
          <div className={cx('right')}>
            {/* <div className={cx('search')}>
              <FaSearch onClick={handleClickSearchBtn}/>
            </div> */}
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
