import React from 'react';
import classNames from 'classnames/bind';
import styles from './Dimmed.module.scss';

const cx = classNames.bind(styles);

const Dimmed = ({ visible, onHideMobileNav }) => {
  return (
    <div>
      {visible && <div className={cx('dimmed')} onClick={onHideMobileNav} />}
    </div>
  );
};

export default Dimmed;
