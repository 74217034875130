import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import PageTemplateContainer from 'src/containers/common/PageTemplateContainer';
import ListWrapper from 'src/components/list/ListWrapper';
import PostList from 'src/components/list/PostList';
import Pagination from 'src/components/list/Pagination';

class DevList extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const posts = data.allMarkdownRemark.edges.map(({ node }) => ({
      id: node.id,
      excerpt: node.excerpt,
      slug: node.fields.slug,
      title: node.frontmatter.title,
      date: node.frontmatter.date,
      description: node.frontmatter.description,
      tags: node.frontmatter.tags,
      image: node.frontmatter.image,
    }));
    return (
      <PageTemplateContainer>
        <Helmet>
          <html lang="ko" />
          <meta
            name="google-site-verification"
            content="CHBdeXNOvzHYfS-eUHaDSNSxiHEAYcXUTNP6K3ddtmE"
          />
          <title>{`개발 포스트 목록 | ${data.site.siteMetadata.title}`}</title>
        </Helmet>
        <ListWrapper>
          <PostList posts={posts} />
          <Pagination
            page={pageContext.pageNumber}
            lastPage={pageContext.lastPage}
          />
        </ListWrapper>
      </PageTemplateContainer>
    );
  }
}

export default DevList;

export const DevListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
