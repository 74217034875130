import React from 'react';
import 'src/styles/base.scss';
import HeaderContainer from 'src/containers/common/HeaderContainer';
import Footer from 'src/components/common/Footer';
import Dimmed from 'src/components/common/Dimmed';
import SearchModalContainer from 'src/containers/modal/SearchModalContainer';

const PageTemplate = ({
  children,
  isMobileMode,
  visibleMobileNav,
  onHideMobileNav,
  visibleSearchModal,
}) => {
  return (
    <div>
      {visibleSearchModal && <SearchModalContainer />}
      <HeaderContainer />
      <div>{children}</div>
      <Footer />
      {isMobileMode && (
        <Dimmed visible={visibleMobileNav} onHideMobileNav={onHideMobileNav} />
      )}
    </div>
  );
};

export default PageTemplate;
