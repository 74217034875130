import React from 'react';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = () => {
  return (
    <footer className={cx('footer')}>
      <div className={cx('footer-content')}>
        <p className={cx('copyright')}>
          Proudly powered by <a href="https://www.gatsbyjs.org">Gatsby</a>
          <br />
          Theme by <a href="https://github.com/yunheur">yunheur</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
