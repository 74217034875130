import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import styles from './Navbar.module.scss';

const cx = classNames.bind(styles);

const Navbar = () => {
  return (
    <nav className={cx('nav')}>
      <ul className={cx('nav-list')}>
        <li className={cx('nav-item')}>
          <Link
            to="/dev/1"
            activeClassName={cx('nav-active')}
            className={cx('nav-link')}
          >
            Dev
          </Link>
        </li>
        <li className={cx('nav-item')}>
          <Link
            to="/tags"
            activeClassName={cx('nav-active')}
            className={cx('nav-link')}
          >
            Tags
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
