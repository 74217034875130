import React from 'react';
import styles from './Pagination.module.scss';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const cx = classNames.bind(styles);

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const { page = 1, lastPage = 1, pageNeighbours = 0 } = props;

    this.currentPage = page;
    this.totalPages = lastPage;
    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;
  }

  createPagePath = page => {
    return `/dev/${page}`;
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    if (this.totalPages === 1) return null;

    const pages = this.fetchPageNumbers();

    return (
      <nav className={cx('pagination')}>
        <ul className={cx('page-list')}>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li key={index} className={cx('page-item')}>
                  <Link
                    to={this.createPagePath(
                      this.currentPage - this.pageNeighbours * 2 - 1
                    )}
                    className={cx('page-link')}
                  >
                    <FaAngleLeft />
                  </Link>
                </li>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <li key={index} className={cx('page-item')}>
                  <Link
                    to={this.createPagePath(
                      this.currentPage + this.pageNeighbours * 2 + 1
                    )}
                    className={cx('page-link')}
                  >
                    <FaAngleRight />
                  </Link>
                </li>
              );
            }

            if (page === this.currentPage) {
              return (
                <li key={index} className={cx('page-item')}>
                  <div
                    className={cx(
                      'page-link',
                      this.currentPage === page ? 'active' : null
                    )}
                  >
                    {page}
                  </div>
                </li>
              );
            }

            return (
              <li key={index} className={cx('page-item')}>
                <Link
                  to={this.createPagePath(page)}
                  className={cx('page-link')}
                >
                  {page}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Pagination;
