import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageTemplate from 'src/components/common/PageTemplate';
import * as baseActions from 'src/store/modules/base';

class PageTemplateContainer extends Component {
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const screenWidth = window.innerWidth;
    let breakpoint;
    if (screenWidth < 480) {
      breakpoint = 'small';
    } else if (screenWidth < 768) {
      breakpoint = 'medium';
    } else if (screenWidth < 1024) {
      breakpoint = 'large';
    } else {
      breakpoint = 'wide';
    }

    const isMobileMode = screenWidth < 768;

    const { BaseActions } = this.props;
    BaseActions.resize({
      breakpoint: breakpoint,
      isMobileMode: isMobileMode,
    });
  };

  handleHideMobileNav = () => {
    const { BaseActions, isMobileMode } = this.props;
    isMobileMode && BaseActions.hideMobileNav();
  };

  render() {
    const {
      children,
      isMobileMode,
      visibleMobileNav,
      visibleSearchModal,
    } = this.props;
    const { handleHideMobileNav } = this;
    return (
      <PageTemplate
        children={children}
        isMobileMode={isMobileMode}
        visibleMobileNav={visibleMobileNav}
        onHideMobileNav={handleHideMobileNav}
        visibleSearchModal={visibleSearchModal}
      />
    );
  }
}

export default connect(
  state => {
    const { base, search } = state;
    return {
      breakpoint: base.breakpoint,
      isMobileMode: base.isMobileMode,
      visibleMobileNav: base.visibleMobileNav,
      visibleSearchModal: search.visibleSearchModal,
    };
  },
  dispatch => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
  })
)(PageTemplateContainer);
