import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from 'src/components/common/Header';
import * as baseActions from 'src/store/modules/base';
import * as searchActions from 'src/store/modules/search';

class HeaderContainer extends Component {
  handleToggleMobileNav = () => {
    const { BaseActions, isMobileMode } = this.props;
    isMobileMode && BaseActions.toggleMobileNav();
  };

  handleHideMobileNav = () => {
    const { BaseActions, isMobileMode } = this.props;
    isMobileMode && BaseActions.hideMobileNav();
  };

  handleShowSearchModal = () => {
    const { SearchActions } = this.props;
    SearchActions.showSearchModal();
  };

  render() {
    const {
      handleToggleMobileNav,
      handleHideMobileNav,
      handleShowSearchModal,
    } = this;
    const { isMobileMode, visibleMobileNav } = this.props;
    return (
      <Header
        onToggleMobileNav={handleToggleMobileNav}
        onHideMobileNav={handleHideMobileNav}
        isMobileMode={isMobileMode}
        visibleMobileNav={visibleMobileNav}
        onShowSearchModal={handleShowSearchModal}
      />
    );
  }
}

export default connect(
  state => {
    const { base } = state;
    return {
      isMobileMode: base.isMobileMode,
      visibleMobileNav: base.visibleMobileNav,
    };
  },
  dispatch => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    SearchActions: bindActionCreators(searchActions, dispatch),
  })
)(HeaderContainer);
