import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SearchModal from 'src/components/common/SearchModal';
import * as searchActions from 'src/store/modules/search';

class SearchModalContainer extends Component {
  handleHideSearchModal = () => {
    const { SearchActions } = this.props;
    SearchActions.hideSearchModal();
  };

  render() {
    const { handleHideSearchModal } = this;
    return <SearchModal onHideSearchModal={handleHideSearchModal} />;
  }
}

export default connect(
  null,
  dispatch => ({
    SearchActions: bindActionCreators(searchActions, dispatch),
  })
)(SearchModalContainer);
