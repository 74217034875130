import React from 'react';
import classNames from 'classnames/bind';
import { FaSearch, FaTimes } from 'react-icons/fa';
import styles from './SearchModal.module.scss';

const cx = classNames.bind(styles);

const SearchModal = ({ onHideSearchModal }) => {
  return (
    <div className={cx('search')}>
      <div className={cx('search-inner')}>
        <div className={cx('search-container')}>
          <div className={cx('search-form')}>
            <input
              className={cx('search-input')}
              placeholder="검색어를 입력해 주세요."
            />
            <div className={cx('search-button')}>
              <FaSearch />
            </div>
          </div>
        </div>
        <div className={cx('search-close')}>
          <FaTimes onClick={onHideSearchModal} />
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
